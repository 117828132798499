import _ from 'lodash'

export const parseCookieString = (cookieStr: string) => {
	if (!cookieStr) {
		return {}
	}
	const cookiesArr = cookieStr.split(/;\s*/)
	return _.reduce(
		cookiesArr,
		(cookieObj: Record<string, string>, part: string) => {
			const [cookieName, cookieVal] = part.split('=')
			cookieObj[cookieName] = cookieVal
			return cookieObj
		},
		{}
	)
}
